<!--
 * @Author: your name
 * @Date: 2020-11-24 14:55:24
 * @LastEditTime: 2021-04-06 15:41:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\adminInverter\createInverter.vue
-->
<template>
    <div class="addCity box-create">
        <p style=""></p>
        <div class="box-title">{{$t('project.evcharger-add')}}</div>
        <el-form ref="form" :model="form" label-width="auto" label-position='right'>
            <el-row :gutter="24">
                <el-col :span="10" :offset="1">
                    <!-- <el-divider content-position="center">{{$t('AC Nominal Input')}}</el-divider> -->
                    <el-form-item :label="$t('project.evcharger-type')" prop="name" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.name" ></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('evcharger.data.Phases/Lines')"  prop="pilePhase" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <!-- <el-radio v-model="form.pilePhase" :label="0">{{$t('single.phase')}}</el-radio>
                        <el-radio v-model="form.pilePhase" :label="1">{{$t('three.phase')}}</el-radio> -->
                        <el-input v-model="form.pilePhase" ></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('evcharger.data.Input-Voltage')+'[V]'"  prop="inputVoltage" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.inputVoltage" type="number" v-number></el-input>
                    </el-form-item>
                    <!-- <el-form-item :label="$t('evcharger.data.Input-Frequency')+'[Hz]'" prop="inputFrequency" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.inputFrequency"></el-input>
                    </el-form-item> -->
                    <el-form-item :label="$t('evcharger.data.Input-Frequency')+'[Hz]'"  prop="inputFrequencyFrom" :rules="[{ required: true, message: $t('remind.not-null')},{validator:test_inputFrequency,trigger:'blur'}]">
                        <el-col :span="11">
                            <el-form-item prop="inputFrequencyFrom" >
                                <el-input v-model="form.inputFrequencyFrom"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col class="line" :span="2">-</el-col>
                        <el-col :span="11">
                            <el-form-item prop="inputFrequencyTo" >
                                <el-input v-model="form.inputFrequencyTo"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item :label="$t('evcharger.data.RFID')"  prop="rfid" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-radio v-model="form.rfid" :label="1">yes</el-radio>
                        <el-radio v-model="form.rfid" :label="0">no</el-radio>
                    </el-form-item>
                    <el-form-item :label="$t('evcharger.data.RS485')"  prop="rs485" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-radio v-model="form.rs485" :label="1">yes</el-radio>
                        <el-radio v-model="form.rs485" :label="0">no</el-radio>
                    </el-form-item>
                    <el-form-item :label="$t('evcharger.data.wall-mount-Bracket')"  prop="wallmountBracket" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-radio v-model="form.wallmountBracket" :label="1">yes</el-radio>
                        <el-radio v-model="form.wallmountBracket" :label="0">no</el-radio>
                    </el-form-item>
                    <el-form-item :label="$t('evcharger.data.operatingTemp')+'[°C]'"  >
                        <el-col :span="11">
                            <el-form-item prop="operatingTempFrom" >
                                <el-input v-model="form.operatingTempFrom"  type="number" v-number @change='changeNomPowerform'></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col class="line" :span="2">-</el-col>
                        <el-col :span="11">
                            <el-form-item prop="operatingTempTo" >
                                <el-input v-model="form.operatingTempTo" type="number" v-number @change='changeNomPowerTo'></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item :label="$t('evcharger.data.workingHumidity')+'[%]'"  >
                        <el-col :span="11">
                            <el-form-item prop="workingHumidityFrom">
                                <el-input v-model="form.workingHumidityFrom"  type="number" v-number @change='changeNomPowerform'></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col class="line" :span="2">-</el-col>
                        <el-col :span="11">
                            <el-form-item prop="workingHumidityTo" >
                                <el-input v-model="form.workingHumidityTo" type="number" v-number @change='changeNomPowerTo'></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    
                    <el-form-item :label="$t('evcharger.data.wirelessModule')" prop="wirelessModule" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input v-model="form.wirelessModule" ></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('evcharger.data.workingAltitude')+'[m]'">
                        <el-input v-model="form.workingAltitude" type="number" v-number></el-input>
                    </el-form-item>

                    <!-- <el-divider content-position="center">{{$t('AC Nominal Output')}}</el-divider> -->
                    <el-form-item :label="$t('evcharger.data.Output-Voltage')+' [V]'" prop="outputVoltage" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.outputVoltage" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('evcharger.data.Output-Current')+'[A]'" prop="outputCurrent" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.outputCurrent" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('evcharger.data.Output-Power')+'[kW]'" prop="outputPower" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.outputPower" type="number" v-number></el-input>
                    </el-form-item>

                    
                    
                </el-col>
                <el-col :span="10" :offset="2">
                   <!-- <el-divider content-position="center">{{$t('General Data')}}</el-divider> -->
                    <el-form-item :label="$t('evcharger.data.Housing-Material')" >
                        <el-input v-model="form.housingMaterial" ></el-input>
                    </el-form-item>
                    
                    <el-form-item :label="$t('evcharger.data.Installation-Method')" >
                        <el-input v-model="form.installationMethod" ></el-input>
                    </el-form-item>
                    
                    <el-form-item :label="$t('evcharger.data.Charging-Outlet')"  >
                        <el-input v-model="form.chargingOutLet" ></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('evcharger.data.Cable-Length')+'[m]'"  >
                       <el-input v-model="form.cableLength" type="number" v-number></el-input>
                    </el-form-item>
                    
                    <el-form-item :label="$t('evcharger.data.Degree-of-Protection')" >
                        <el-input v-model="form.degreeofProtection"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('evcharger.data.Application-Site')" >
                        <el-input v-model="form.applicationSite"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('evcharger.data.Cooling-Concept')" >
                        <el-input v-model="form.coolingConcept"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('evcharger.data.width')+'[mm]'" prop="width" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input v-model="form.width" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('evcharger.data.depth')+'[mm]'" prop="depth" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input v-model="form.depth" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('evcharger.data.height')+'[mm]'" prop="height" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input v-model="form.height" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('evcharger.data.Net-Weigth')+'[kg]'" prop="netWeight" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input v-model="form.netWeight" type="number" v-number></el-input>
                    </el-form-item>
                    
                    <el-form-item label="Hardware"  prop="hardware" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <!-- <el-radio v-model="form.pilePhase" :label="0">{{$t('single.phase')}}</el-radio>
                        <el-radio v-model="form.pilePhase" :label="1">{{$t('three.phase')}}</el-radio> -->
                        <el-checkbox-group v-model="form.hardware">
                            <el-checkbox label="1">Plug</el-checkbox>
                            <el-checkbox label="2">Socket</el-checkbox>
                            <el-checkbox label="3">LCD</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="Application"  prop="application" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-radio v-model="form.application" :label="0">Home</el-radio>
                        <el-radio v-model="form.application" :label="1">Commercial</el-radio>
                    </el-form-item>
                </el-col>
       
            </el-row>
        </el-form>
        <div class="btn-wrap">
            <el-button type="primary" @click="saveForm('form')">{{$t('userInfo.alter')}}</el-button>
            <el-button @click="()=>{$router.push({path:'/adminEvCharger'})}">{{$t('uerInfo.back')}}</el-button>
        </div>
    </div>
</template>

<script>
import baseFile from '../../components/common/baseFileInput'
export default {
    components: {
        baseFile
    },
    props: {

    },
    data() {
        this.test_inputFrequency = async(rule, value, callback) => {
            if(this.form.inputFrequencyFrom==''||this.form.inputFrequencyTo == ''){
                callback(new Error(this.$t('remind.not-null')));
            }else{
               callback();
            }
        }
        return {
            form:{
                hardware:[]
            },
            chargerId: this.$route.query.id,
        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
        if(this.chargerId){
            this.tchargepileGetById()
        }

    },
    methods: {
        changeNomPowerform(){
            let nomPowerTo = this.form.nomPowerTo && parseInt(this.form.nomPowerTo)
            let nomPowerFrom = this.form.nomPowerFrom ? parseInt(this.form.nomPowerFrom) : ''
            this.form.nomPowerFrom = nomPowerFrom > nomPowerTo ? nomPowerTo - 1 :nomPowerFrom
        },
        changeNomPowerTo(){
            let nomPowerTo = this.form.nomPowerTo? parseInt(this.form.nomPowerTo) : ''
            let nomPowerFrom = this.form.nomPowerFrom ? parseInt(this.form.nomPowerFrom) : ''
            if((nomPowerTo <= nomPowerFrom) && nomPowerFrom && nomPowerTo){
                this.$message({
                    message: this.$t('nomPowerFrom.big'),
                    type: 'warning'
                });
                this.form.nomPowerTo = parseInt(nomPowerFrom) + 1
                nomPowerTo = parseInt(nomPowerFrom) + 1
            }
            if(nomPowerTo >150 || nomPowerTo  == 150){
                // console.log(this.form.nomPowerTo,'?');
                this.form.nomPowerTo = 149
            }else if(nomPowerTo <=100){
                this.form.nomPowerTo = 100
            }
        },
        /*获取详情数据*/
        async tchargepileGetById(){
            let res = await this.API.tchargepileGetById({
                id: this.chargerId
            })
            this.form = res
            if (res.hardware) {
                this.form.hardware = res.hardware.split(',')
            }else{
                this.form.hardware = []
            }
        },

        

        /*保存提交*/
        saveForm(formName){
            let $this = this
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if($this.chargerId){
                        $this.update($this.form)
                    }else {
                        $this.save($this.form)
                    }
                } else {
                    return false;
                }
            });
        },

        /*更新*/
        async update(data){
            data.hardware = data.hardware.join()
            let res = await this.API.tchargepileUpdate(data)
            this.showMessage(res)

        },

        /*创建*/
        async save(data){
            let res = await this.API.tchargepileSave(data)
            this.showMessage(res)
        },

        showMessage(res){
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.$router.push({path: '/adminEvCharger'});
            }else {
                this.$message({
                    message: res.msg,
                    type: 'warning'
                });
            }
        },


    },
};
</script>

<style lang="less">
.box-create{
    .box-title{
        margin-bottom: 10px;
        font-weight: 700;
    }
    .el-form{
        .el-divider{
            white-space: nowrap;
        }
        .el-input-group__append, .el-input-group__prepend{
            padding: 0;
            width: 50px;
            text-align: center;
            font-size: 12px;
        }
        .el-input-group+.el-input-group{
            margin-left:10px;
        }
        .el-input-group.inline{
            width:112px;
        }
        .el-col-11,.el-col-2{
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .el-col-2{
            text-align: center;
        }
    }
}
.el-form-item__error{
    word-break:keep-all;
     width: fit-content;
}
</style>

